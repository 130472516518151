/*---
Custom Color add to theme
---*/

//Theme Variable
$default: #3c4055;
$danger: #ff355d;
$info: #0024a7;
$premium: #5b00ac;
$secondary: #1a46e5;
$bluish-grey: #7b8997;
$light-grey-default: #aabaca;
$light-grey-blue: #3e68ff;
$pale-blue: #e0e4e8;
$lightish-blue: #3e68ff;
$rosa: #ff88a6;
$golden: #edc400;
$sun-yellow: #ffd81d;
$dark-mint: #4bc976;
$sea-green: #52ee87;
$violet: #9013fe;
$light-violet: #a3b8cc;

$miggsterGrey: #121217; // rgb(18,18,23)
$miggsterRed: #c9103f;

$miggsterBGDarkGrey: #121217;
$miggsterBGLightGrey: #202029; // rgb(32,32,41)
$miggsterTextHeading: #BBB;
$miggsterTextParagraph: #868686;
$miggsterButtonPrimary: #C9123F;
$miggsterButtonPrimaryText: #FFFFFF;
$miggsterButtonSecondary: #14151E;
$miggsterButtonSecondaryText: #464672;
$miggsterBGSection: #202129;
$miggsterTextAccent: #EA3A54;

$theme-colors: map-merge(
  $theme-colors,
  (
    "default": $default,
    "danger": $danger,
    "info": $info,
    "premium": $premium,
    "secondary": $secondary,
    "bluish-grey": $bluish-grey,
    "light-grey-default": $light-grey-default,
    "light-grey-blue": $light-grey-blue,
    "pale-blue": $pale-blue,
    "lightish-blue": $lightish-blue,
    "rosa": $rosa,
    "golden": $golden,
    "sun-yellow": $sun-yellow,
    "dark-mint": $dark-mint,
    "sea-green": $sea-green,
    "violet": $violet,
    "light-violet": $light-violet,
    "miggsterGrey": $miggsterGrey,
    "miggsterRed": $miggsterRed,
    "miggsterBGDarkGrey": $miggsterBGDarkGrey,
    "miggsterBGLightGrey": $miggsterBGLightGrey,
    "miggsterTextHeading": $miggsterTextHeading,
    "miggsterTextParagraph": $miggsterTextParagraph,
    "miggsterButtonPrimary": $miggsterButtonPrimary,
    "miggsterButtonPrimaryText": $miggsterButtonPrimaryText,
    "miggsterButtonSecondary": $miggsterButtonSecondary,
    "miggsterButtonSecondaryText": $miggsterButtonSecondaryText,
    "miggsterTextAccent": $miggsterTextAccent,
  )
);

//Heading
$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
//Button
//Card
$card-border-width: 0px;
$card-img-overlay-padding: 0px;
//Navbar Light
$navbar-light-color: rgba($default, 0.9);
$navbar-light-hover-color: rgba($danger, 1);
$navbar-light-active-color: $danger;
//Nvigation padding
$navbar-nav-link-padding-x: 1rem;

//Font

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Montserrat", sans-serif;
$font-family-monospace: "Montserrat", sans-serif;
$font-family-base: $font-family-sans-serif;

$container-max-widths: map-merge(
  $container-max-widths,
  (
    xl: 1200px
  )
);
